import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { APIContext } from "../../context/api";

function LoginPage() {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const [accessToken, setAccessToken] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const oauthCode = queryParams.get('code');

  const startAuthClick = () => {
    fetchAPI({
      path: '/auth/get_oauth_url',
      query: {
        redirect_url: window.location.origin + window.location.pathname,
      },
    }).then(response => {
      console.log(response);
      if (response?.success && response?.oauth_url) {
        window.location.href = response.oauth_url;
      }
    });
  };

  const authWithCode = ({ code, state }) => {
    fetchAPI({
      path: '/auth/get_token',
      query: {
        code: code,
        state: state,
        redirect_url: window.location.origin + window.location.pathname,
      },
    }).then(response => {
      if (response?.success) {
        setAccessToken(response.access_token);
      }
    });
  };

  useEffect(() => {
    if (queryParams.get('code')) {
      authWithCode({
        code: queryParams.get('code'),
        state: queryParams.get('state'),
      });
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("access_token", accessToken.toString());
      window.location.href = '/shares/list';
    }
  }, [accessToken]);

  return (
    <div className={'mt-5 mb-3 text-center'}>
      {!!oauthCode && (
        <p>Please wait...</p>
      )}

      {!oauthCode && (
        <Button onClick={startAuthClick} variant="success" size="lg">
          Sign in
        </Button>
      )}
    </div>
  );
}

export default LoginPage;
