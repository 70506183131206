import React from "react";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { AlertProvider } from "./context/alert";
import { APIProvider } from "./context/api";
import { WebsocketProvider } from "./context/websockets";
import AppRoutes from "./routes";


function App() {
  return (
    <AlertProvider>
      <APIProvider>
        <WebsocketProvider>
          <BrowserRouter>
            <AppRoutes/>
          </BrowserRouter>
        </WebsocketProvider>
      </APIProvider>
    </AlertProvider>
  );
}

export default App;
