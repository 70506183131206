import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Menu from "./Menu";
import { AlertContext } from "../context/alert";
import AlertDismissible from "./alert";
import Notifications from "./Notifications";

const Layout = ({ fluid = false }) => {
  const { visible, alertVariant, alertTitle, alertMessage, setVisible } = useContext(AlertContext);

  return (
    <>
      <Menu/>
      <Container fluid={fluid} className={!fluid ? 'my-md-3' : ''}>
        <Row>
          <Col>
            <AlertDismissible
              visible={visible}
              variant={alertVariant}
              title={alertTitle}
              message={alertMessage}
              onClose={() => setVisible(false)}
            />
            <Outlet/>
          </Col>
        </Row>
      </Container>
      <Notifications/>
    </>
  )
};

export default Layout;
