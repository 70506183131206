import { createContext } from "react";

const env = require('env-var');

export const ConfigContext = createContext({
  // env variables names must start with REACT_APP_
  apiUrl: env.get('REACT_APP_API_URL')
    // for local development, set env var to http://127.0.0.1:8000/api/
    .default('/api/').asString()
    .replace(/\/$/, ''),
  websocketsUrl: env.get('REACT_APP_WS_URL')
    // for local development, set env var to ws://127.0.0.1:8000/ws/
    .default(`wss://${window.location.host}/ws/`).asString()
    .replace(/\/$/, ''),
});
