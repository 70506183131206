import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ConfigContext } from "./config";
import useWebSocket, { ReadyState } from "react-use-websocket";

export const WSContext = createContext({
  connectionStatus: null,
  lastEvent: undefined,
});

export const WebsocketProvider = ({ children }) => {
  const { websocketsUrl } = useContext(ConfigContext);
  const [lastEvent, setLastEvent] = useState(null);

  const {
    sendMessage,
    lastMessage,
    readyState,
  } = useWebSocket(websocketsUrl, {
    onOpen: () => console.log('ws opened'),
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      const event = JSON.parse(lastMessage.data);
      if (event?.ping) {
        sendMessage(JSON.stringify({'ping': 'pong'}));
        return;
      }
      setLastEvent(event);
    }
  }, [lastMessage, setLastEvent]);

  const handleClickSendMessage = useCallback(() =>
    sendMessage(JSON.stringify({'foo': 'bar'})), []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <WSContext.Provider value={{ connectionStatus, lastEvent }}>
      {children}
    </WSContext.Provider>
  );
}
