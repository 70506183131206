import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/alert";
import { APIContext } from "../../context/api";

import { Col, Form, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";

import AudioCard from "./AudioCard";
import CreateShareModel from "./CreateShareModel";


function TranscriptAudioPage() {
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const [audioItems, setAudioItems] = useState(null);
  const [lastSyncKey, setLastSyncKey] = useState(null);
  const [hasMoreAudioItems, setHasMoreAudioItems] = useState(null);
  const [submissions, setSubmissions] = useState({});
  const [minLength, setMinLength] = useState(5);
  const [maxLength, setMaxLength] = useState(21);
  const [minWordsPerShard, setMinWordsPerShard] = useState('');
  const [maxProblemTags, setMaxProblemTags] = useState(6);
  const [hasTranscriptFilter, setHasTranscriptFilter] = useState(true);
  const [hasNoTranslationFilter, setHasNoTranslationFilter] = useState(true);
  const [evaluationFilter, setEvaluationFilter] = useState(false);
  const [siteNameFilter, setSiteNameFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');

  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 40);
  const defaultEndDate = new Date();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [isAudioItemsWithTranscript, setIsAudioItemsWithTranscript] = useState(null);
  const [currentAudioFilters, setCurrentAudioFilters] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState({codes: [], map: {}});
  const [createShareShown, setCreateShareShown] = useState(false);

  const dateRangeInvalid = !!(startDate && endDate && startDate > endDate);

  useEffect(() => {
    setCurrentAudioFilters(null);
    setLastSyncKey(null);
    setHasMoreAudioItems(null);
  }, [
    minLength, maxLength, minWordsPerShard, maxProblemTags, hasTranscriptFilter,
    hasNoTranslationFilter, evaluationFilter, startDate, endDate, siteNameFilter,
    languageFilter,
  ]);

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = () => {
    return fetchAPI({
      path: '/languages/',
      query: {},
    }).then(response => {
      if (!response.success) {
        return;
      }
      setAvailableLanguages({
        codes: response.lang_codes || [],
        map: response.lang_map || {},
      });
    });
  };

  const getAudios = (syncKey) => {
    const audioFilters = {
      start_date: startDate.toISOString().substring(0, 10),
      end_date: endDate.toISOString().substring(0, 10),
      has_human_transcript: hasTranscriptFilter,
      has_no_translation: hasNoTranslationFilter,
      evaluation: evaluationFilter,
    };
    if (minWordsPerShard) {
      audioFilters.min_words_per_shard = minWordsPerShard;
    }
    if (maxProblemTags) {
      audioFilters.max_problem_tags = maxProblemTags;
    }
    if (minLength) {
      audioFilters.min_length_sec = minLength;
    }
    if (maxLength) {
      audioFilters.max_length_sec = maxLength;
    }
    if (siteNameFilter) {
      audioFilters.site_name = siteNameFilter;
    }
    if (languageFilter) {
      audioFilters.language = languageFilter;
    }

    return fetchAPI({
      path: '/audios/',
      query: {
        ...audioFilters,
        sync_key: syncKey || '',
      },
    }).then(response => {
      setHasMoreAudioItems(response.has_more || null);
      setLastSyncKey(response.sync_key || null);
      setIsAudioItemsWithTranscript(hasTranscriptFilter);
      setCurrentAudioFilters(audioFilters);
      return response.items || [];
    });
  };

  const onLookupAudios = () => {
    setAudioItems([]);
    getAudios(null).then((items) => {
      setAudioItems(items || []);
    });
  }

  const loadMoreResults = () => {
    if (lastSyncKey) {
      getAudios(lastSyncKey).then((newItems) => {
        setAudioItems([...audioItems, ...newItems]);
      });
    }
  };

  const submitTranscript = (e, audioId) => {
    e.preventDefault();
    const transcriptionText = e.target?.elements?.transcriptionText?.value;
    if (!transcriptionText || transcriptionText.length < 2) {
      showAlertMessage({ message: 'Text is required' });
      return;
    }

    return fetchAPI({
      path: '/audio/label',
      query: {
        audio_id: audioId,
      },
      data: {
        transcription: transcriptionText,
      },
    }).then(response => {
      setSubmissions({
        ...submissions,
        [audioId]: response?.submission_uid || null,
      });
    });
  };

  const onCreateShareClose = (res) => {
    setCreateShareShown(false);
    if (res) setAudioItems([]);
  };

  const getTotalAudioItemsMinutes = (items) => {
    const audioMs = items?.reduce((prev, item) => prev + item.metadata.length_ms, 0) || 0;
    return (audioMs / 1000 / 60).toFixed(2);
  };

  const getTotalAudioItemsHours = (items) => {
    return (getTotalAudioItemsMinutes(items) / 60).toFixed(2);
  };

  const getLangName = (langCode) => {
    if (availableLanguages?.map && availableLanguages.map[langCode]) {
      return availableLanguages.map[langCode][0];
    }
    return null;
  };

  return (
    <div>
      <div className={'mb-4'}>
        <h2>Fetch audio with transcripts to translate</h2>
        <hr/>
      </div>

      <div>
        <h3>Filters</h3>
      </div>
      <Row>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>Min length (seconds)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Audio min length (seconds)"
              step={1}
              aria-valuemin={0}
              value={minLength}
              onChange={(e) => setMinLength(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>Max length (seconds)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Audio max length (seconds)"
              step={1}
              aria-valuemin={0}
              value={maxLength}
              onChange={(e) => setMaxLength(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>Min words per shard</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              step={1}
              aria-valuemin={0}
              value={minWordsPerShard}
              onChange={(e) => setMinWordsPerShard(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>Max problematic tags (foreign, etc.)</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              step={1}
              aria-valuemin={0}
              value={maxProblemTags}
              onChange={(e) => setMaxProblemTags(e.target.value)}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            <Form.Label>Start date</Form.Label>
            <DatePicker
              className={'form-control'}
              dateFormat="yyyy-MM-dd"
              maxDate={new Date()}
              selected={startDate}
              onChange={(date) => setStartDate(date)}/>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>End date</Form.Label>
            <DatePicker
              className={'form-control'}
              dateFormat="yyyy-MM-dd"
              maxDate={new Date()}
              selected={endDate}
              onChange={(date) => setEndDate(date)}/>
            {dateRangeInvalid && (<span className={'text-danger'}>
              End date must be greater than start date.
            </span>)}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>Site name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={siteNameFilter}
              onChange={(e) => setSiteNameFilter(e.target.value)}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Check
            type="switch"
            id="HumanTranscript"
            label="Has Human Transcript"
            checked={hasTranscriptFilter}
            onChange={() => setHasTranscriptFilter(!hasTranscriptFilter)}
          />
          <Form.Check
            type="switch"
            id="HasTranslation"
            label="Has no translation yet"
            checked={hasNoTranslationFilter}
            onChange={() => setHasNoTranslationFilter(!hasNoTranslationFilter)}
          />
          <Form.Check
            type="switch"
            id="Evaluation"
            label="Evaluation"
            checked={evaluationFilter}
            onChange={() => setEvaluationFilter(!evaluationFilter)}
          />

          <Form.Group className="mt-2">
            <Form.Label>Transcript language</Form.Label>
            <Form.Select
              aria-label="Language"
              onChange={(e) => setLanguageFilter(e.target.value)}
              disabled={!!isAPILoading}
            >
              <option value=''>Any language</option>
              {availableLanguages.codes.map(lang => (
                <option value={lang} key={lang}>{lang} - {getLangName(lang)}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Button
            onClick={() => onLookupAudios()}
            disabled={isAPILoading}
            className={'me-2 mb-2'}
            variant={'outline-primary'}
          >
            Lookup Audios
            {isAPILoading && (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={'ms-1'}
            />)}
            {!isAPILoading && (<span>🕵️‍♀️</span>)}
          </Button>

        </Col>
      </Row>

      <Row>
        <Col>
          <div className={'text-center'}>
            <div className={'mt-3 mb-2'}>
              {!hasMoreAudioItems && (
                <span>Found items: <strong>{audioItems?.length || 0}</strong></span>
              )}
              {!!hasMoreAudioItems && (
                <span>
                  Found items: <strong> > {audioItems?.length || 0}</strong>
                  <Button variant={'link'} size={'sm'} className={'text-muted'} onClick={() => loadMoreResults()}>
                    load more results
                  </Button>
                </span>
              )}
            </div>
            <div className={'mb-1'}>
              {(!!audioItems?.length) && (
                <p>
                  Total audio minutes in displayed {audioItems.length} items:
                  <strong> {getTotalAudioItemsMinutes(audioItems)} ({getTotalAudioItemsHours(audioItems)} hours)</strong>
                </p>
              )}
            </div>
            <div className={'mb-2'}>
              <Button
                onClick={() => setCreateShareShown(true)}
                disabled={!currentAudioFilters || isAPILoading}
              >
                Create zip for annotators using current filters ✨
              </Button>
              <p className={'text-muted small'}>Currently displayed items may vary from what is included in zip</p>
            </div>

          </div>
        </Col>
      </Row>

      {audioItems?.map((audio) => (
        <AudioCard key={audio.id} audio={audio}>
          {!isAudioItemsWithTranscript && (
            <div>
              {!submissions[audio.id] ? (
                <Form
                  onSubmit={(e) => submitTranscript(e, audio.id)}
                  aria-disabled={isAPILoading}
                >
                  <Form.Group className="mb-3" controlId="transcriptionText">
                    <Form.Label>
                      <div>Transcript</div>
                      <span className={'text-muted'}>For debugging purposes you can add transcript here</span>
                    </Form.Label>
                    <Form.Control type="text" name="transcription_text" disabled={isAPILoading}/>
                  </Form.Group>
                  <Button type='submit'>Submit</Button>
                </Form>
              ) : (
                <div>
                  <span>Submitted:</span>
                  <code>{submissions[audio.id]}</code>
                </div>
              )}
            </div>
          )}
        </AudioCard>
      ))}

      <CreateShareModel currentAudioFilters={currentAudioFilters} show={createShareShown} onClose={onCreateShareClose}/>
    </div>
  );
}

export default TranscriptAudioPage;
