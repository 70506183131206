import React, { useContext, useEffect, useState } from "react";

import { Modal, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { APIContext } from "../../context/api";
import PrettyPrintJson from "../helpers/PrettyPrintJson";


function FindDuplicatesModal({ show, onClose, enableEscape, shareId }) {
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const [result, setResult] = useState(null);

  const queryCheckResult = () => {
    return fetchAPI({
      path: '/shares/find_duplicates',
      query: {share_id: shareId},
      method: 'POST',
    }).then(response => {
      if (response?.success) {
        setResult(response);
      }
    });
  };

  useEffect(() => {
    queryCheckResult();
  }, []);

  return (
    <div className="static-modal">
      <Modal show={show} onHide={() => onClose(false)} backdrop={enableEscape ? true : 'static'}
             keyboard={enableEscape}>
        <Modal.Header>
          <Modal.Title>Find audios present in other shares</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {result?.found_duplicates_count === 0 && (
              <h6 className={'alert-success'}>No audio ids in this share has been found in another shares</h6>
            )}
            {result?.found_duplicates_count > 0 && (
              <h6 className={'alert-warning'}>{result.found_duplicates_count} audio ids in this share has been found in another shares</h6>
            )}

            <Spinner animation="border" size="sm" style={{ opacity: isAPILoading ? 1 : 0 }}/>
            {!!result && (<PrettyPrintJson data={result?.found_duplicates} />)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isAPILoading} variant={'secondary'} onClick={() => onClose(false)}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default FindDuplicatesModal;
