import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AlertContext } from "../../context/alert";
import { APIContext } from "../../context/api";
import PrettyPrintJson from "../helpers/PrettyPrintJson";


function VendorUploadPage() {
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadedFileValid, setIsUploadedFileValid] = useState(false);
  const [uploadedFileVerification, setUploadedFileVerification] = useState(null);
  const [translatedBatchId, setTranslatedBatchId] = useState(false);
  const [shareId, setShareId] = useState('');
  const [isDryRun, setIsDryRun] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState({ codes: [], map: {} });

  useEffect(() => {
    getLanguages();
  }, []);

  const verifyTranslationsFile = useCallback(() => {
    return fetchAPI({
      path: '/upload/translation/verify',
      query: {},
      data: {
        share_id: shareId,
      },
      filesData: [['csv_file', selectedFile]],
    }).then(response => {
      if (!!response) {
        setUploadedFileVerification(response);
      }
    });
  }, [selectedFile, isUploadedFileValid, shareId]);

  const downloadSample = () => {
    return fetchAPI({
      path: '/download_translated_sample',
      query: {},
      downloadAsFile: 'sample.csv',
    });
  };

  const getLanguages = () => {
    return fetchAPI({
      path: '/languages/',
      query: {},
    }).then(response => {
      if (!response?.success) return;
      setAvailableLanguages({
        codes: response.lang_codes || [],
        map: response.lang_map || {},
      });
    });
  };

  const submitUploadedFile = useCallback(() => {
    return fetchAPI({
      path: '/upload/translation/submit',
      query: {},
      data: {
        share_id: shareId,
        dry_run: isDryRun,
      },
      filesData: [['csv_file', selectedFile]],
    }).then(response => {
      if (response?.success) {
        setTranslatedBatchId(response.batch_id);
        showAlertMessage({
          message: 'Please wait for notification',
          variant: 'success',
          title: `Batch ID: ${response.batch_id}`,
        });
      }
    });
  }, [uploadedFileVerification, isDryRun]);

  const validateUploadedFile = (file) => {
    let isValid = true;

    if (file?.size > 1024 * 1024 * 20) {
      showAlertMessage({ message: 'File is too large', variant: 'warning' });
      isValid = false;
    }

    setIsUploadedFileValid(isValid);
  }


  useEffect(() => {
    setTranslatedBatchId(null);
    setIsUploadedFileValid(false);
    setUploadedFileVerification(null);
    validateUploadedFile(selectedFile);
  }, [selectedFile]);

  const canVerify = !isAPILoading && !!selectedFile && !!isUploadedFileValid && !translatedBatchId;
  const canSubmit = !isAPILoading && !!uploadedFileVerification?.success && !translatedBatchId;
  const hasNonShareAudios = (
    uploadedFileVerification?.detailed_result?.total_audios_ids_present_in_share <
    uploadedFileVerification?.detailed_result?.total_unique_audio_ids_translated
  );
  const nonShareAudiosNum = hasNonShareAudios && (
    uploadedFileVerification?.detailed_result?.total_unique_audio_ids_translated -
    uploadedFileVerification?.detailed_result?.total_audios_ids_present_in_share
  ) || 0;

  const totalUniqueAudioIds = uploadedFileVerification?.detailed_result?.total_unique_audio_ids_translated ?? 0;
  const specifiedShareFound = !!uploadedFileVerification?.detailed_result?.share_id;

  return (
    <div>
      <div className={'mb-4'}>
        <h2>Upload translation from annotators</h2>
      </div>

      <Form.Group className="mb-1">
        <Form.Label>Annotators Share ID (optional)</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          value={shareId}
          onChange={(e) => setShareId(e.target.value)}/>
      </Form.Group>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>CSV file with translations</Form.Label>
        <Form.Control
          name="csvfile"
          type="file"
          onChange={(e) => setSelectedFile(e.target.files[0])}
        />
      </Form.Group>

      <Button
        onClick={verifyTranslationsFile}
        disabled={!canVerify}
      >
        Upload and verify
      </Button>

      {uploadedFileVerification?.success && (
        <div className={'m-4'}>
          {!!hasNonShareAudios && (
            <div className={'alert-warning'}>{nonShareAudiosNum} audio(s) in file are not present in the provided share</div>
          )}
          {specifiedShareFound && !hasNonShareAudios && (
            <div className={'alert-success'}>All {totalUniqueAudioIds} audio(s) in file are present in the provided share</div>
          )}

          <PrettyPrintJson data={uploadedFileVerification}/>

          <div className={'mb-2'}>
            <Form.Check
              type="switch"
              id="dry_run"
              label="Dummy upload. Do not upload to trainable"
              checked={isDryRun}
              onChange={() => setIsDryRun(!isDryRun)}
            />
          </div>

          <Button
            disabled={!canSubmit}
            onClick={submitUploadedFile}
            variant={'success'}
          >
            Submit
          </Button>
        </div>
      )}

      <div className={'mt-5'}>
        <hr/>
        <Button onClick={downloadSample} variant={'outline-secondary'}>Download sample</Button>

        <div className={'mt-4'}>
          <p className={'text-muted'}>
            Language column name can be language's full name or short code.
            <br/>
            Name columns with translation as one of these:
          </p>
          <ul className={'text-muted'}>
            {availableLanguages.codes.map(lang => (
              <li key={lang}>{availableLanguages.map[lang].join(', ')}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default VendorUploadPage;
