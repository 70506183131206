import React, { createContext, useState } from "react";

export const AlertContext = createContext({
  showAlertMessage: ({ variant, message, title }) => {
  },
  hideAlertMessage: () => {
  },
});


export const AlertProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertVariant, setAlertVariant] = useState(null);

  const showAlertMessage = ({ variant, message, title }) => {
    setVisible(false);
    setAlertMessage(message);
    setAlertVariant(variant || 'info');
    setAlertTitle(title || null);
    setVisible(true);
  }

  const hideAlertMessage = () => {
    setVisible(false);
  }

  return (
    <AlertContext.Provider value={{
      visible,
      setVisible,
      alertMessage,
      alertTitle,
      alertVariant,
      showAlertMessage,
      hideAlertMessage,
    }}>
      {children}
    </AlertContext.Provider>
  );
}
