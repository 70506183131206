import React, { useContext, useState } from "react";
import { Badge, Card, Form, ProgressBar } from "react-bootstrap";
import PrettyPrintJson from "../helpers/PrettyPrintJson";
import { copyToClipboard, timeAgo, timestampToDate } from "../../utils";
import Button from "react-bootstrap/Button";
import confirm from "../confirm";
import { APIContext } from "../../context/api";
import FindDuplicatesModal from "./FindDuplicatesModal";

function ShareCard({ children, item, progress, highlighted, onMutation }) {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const [urlCopied, setUrlCopied] = useState(false);
  const [noteText, setNoteText] = useState(item.note_text || '');
  const [showFindDuplicatesModal, setShowFindDuplicatesModal] = useState(false);
  const [isNoteTextChanged, setIsNoteTextChanged] = useState(false);

  if (!item) {
    return null;
  }

  const isFinished = item?.status === 'PUBLISHED';

  let createdAt = '';
  if (item.timestamp) {
    createdAt = timestampToDate(item.timestamp).toLocaleString();
  }

  let expiresAt = '';
  if (item.public_url_expires_at) {
    expiresAt = timestampToDate(item.public_url_expires_at).toLocaleString();
  }

  const languages = item.metadata.languages || '';
  const isEmpty = parseInt(item.metadata['total_transcripts_num'] || 0) === 0;

  const isExpired = (item) => {
    if (!item?.public_url_expires_at) return;
    return timestampToDate(item.public_url_expires_at) - new Date() < 0;
  };

  const deleteShare = async (shareId) => {
    const result = await confirm({ confirmation: `Are you sure you want to delete ${shareId}?` });
    if (!result) return;
    return fetchAPI({
      method: 'DELETE',
      path: '/shares/',
      query: {
        share_id: shareId,
      },
    }).then(response => {
      if (response?.success) {
        onMutation();
      }
    });
  };

  const extendShareLink = (shareId) => {
    return fetchAPI({
      method: 'POST',
      path: '/shares/extend_link',
      query: {
        share_id: shareId,
      },
    }).then(response => {
      if (response?.success) {
        onMutation();
      }
    });
  };

  const saveNewNote = (shareId) => {
    return fetchAPI({
      method: 'POST',
      path: '/shares/save_note',
      query: {
        share_id: shareId,
        note_text: noteText,
      },
    }).then(response => {
      if (response?.success) {
        setIsNoteTextChanged(false);
        onMutation();
      }
    });
  };

  const openItem = () => {
    if (item?.public_url) {
      // window.open(item.public_url);
      copyToClipboard(item.public_url);
      setUrlCopied(true);
      setTimeout(function () {
        setUrlCopied(false);
      }, 2000);
    }
  };

  const checkForDuplicates = () => {
    setShowFindDuplicatesModal(true);
  };

  const getTranslationProgress = (langKey) => {
    const left = item.metadata.total_audios_num - item.translations[langKey].translated_audio_count;
    return ((item.metadata.total_audios_num - left) / item.metadata.total_audios_num * 100).toFixed(2);
  }

  const isFulfilled = () => {
    if (!isFinished) {
      return false;
    }
    const fulfilledLength = !!(
      item?.metadata?.requested_duration_sec &&
      item?.metadata?.total_audio_length_seconds >=
      item?.metadata?.requested_duration_sec
    );
    const fulfilledCount = !!(
      item?.metadata?.requested_count &&
      item?.metadata?.total_transcripts_num >=
      item?.metadata?.requested_count
    );
    const noRequested = (
      !item?.metadata?.requested_duration_sec &&
      !item?.metadata?.requested_count
    );

    return fulfilledLength || fulfilledCount || noRequested;
  }

  const noteTextChanged = (newText) => {
    setNoteText(newText);
    setIsNoteTextChanged(true);
  }

  return (
    <Card
      body
      className='mb-4 mt-2'
      style={highlighted ? { borderColor: 'gold', backgroundColor: '#fafafa' } : { backgroundColor: '#fafafa' }}
    >
      <code>{item.share_id}</code>
      <div>
        Created at: {createdAt}
        <br/>
        {!isExpired(item) && (<span>Expires at: {expiresAt}</span>)}
      </div>
      <div className='mb-2'>
        {isFinished && !!isEmpty && (
          <Badge bg="danger" className='me-2'>
            Empty
          </Badge>
        )}

        {!!languages && (
          <Badge bg="primary" className='me-2'>
            Ground truth: {languages}
          </Badge>
        )}

        {!!isExpired(item) && (
          <Badge bg="warning" text="dark" className='me-2'>
            Expired
          </Badge>
        )}

        {isFinished && !!isFulfilled(item) && (
          <Badge bg="success" text="white" className='me-2'>
            Request fulfilled 🌈
          </Badge>
        )}
        {isFinished && !isFulfilled(item) && !isEmpty && (
          <Badge bg="secondary" text="white" className='me-2'>
            Request not fulfilled 👻
          </Badge>
        )}
      </div>

      {Object.keys(item.translations || {}).map((langKey => {
        const translationProgress = getTranslationProgress(langKey);
        const progressBarLabel = `${translationProgress}% translated`;
        return (
          <div className={'mb-2'} style={{ maxWidth: 250 }} key={langKey}>
            <ProgressBar variant={translationProgress > 99 ? "success" : "info"} now={translationProgress}
                         label={``}/>
            <span className={'text-muted small'}>{langKey} {progressBarLabel}</span>
          </div>
        );
      }))}

      <div>
        <Form.Group className="mb-3" controlId="note">
          <Form.Control
            value={noteText}
            as="textarea"
            placeholder="Leave a note here"
            onChange={(e) => noteTextChanged(e.target.value)}/>
        </Form.Group>
        {!!isNoteTextChanged && (
          <Button className={'btn-secondary mb-3'} onClick={() => saveNewNote(item.share_id)}>Save note</Button>
        )}
      </div>

      {item.status === 'PENDING' && !!progress && (
        <div>
          <h4>Progress {progress.stage} 🦄</h4>
          {!!progress?.timestamp && (
            <p className={'text-muted'}>last updated {timeAgo.format(timestampToDate(progress?.timestamp))}</p>)}
          {progress?.progress_percents !== null && (
            <ProgressBar animated striped now={progress.progress_percents} label={`${progress?.progress_percents}%`}/>
          )}
          <PrettyPrintJson data={progress}/>
          <hr/>
        </div>
      )}

      <PrettyPrintJson data={item}/>

      <div>
        {!!isExpired(item) && item.public_url && (
          <Button disabled={isAPILoading} onClick={() => extendShareLink(item.share_id)} className={'me-2'}
                  variant={'warning'}>Extend link for more 24h</Button>
        )}
        {!isExpired(item) && !!item.public_url && (
          <Button disabled={isAPILoading} onClick={() => openItem()} className={'me-2'}>
            Copy URL to clipboard
            {urlCopied && (<span>👌</span>)}
          </Button>
        )}
        {!!item.public_url && (
          <Button className={'me-2'} disabled={isAPILoading} variant={'outline-secondary'}
                  onClick={() => checkForDuplicates(item.share_id)}>
            Check for duplicates
          </Button>
        )}
        <Button className={'me-2'} disabled={isAPILoading} variant={'danger'}
                onClick={() => deleteShare(item.share_id)}>
          Delete
        </Button>
      </div>
      <div>
        {children}
      </div>

      {!!showFindDuplicatesModal && (
        <FindDuplicatesModal show={true} onClose={() => setShowFindDuplicatesModal(false)} shareId={item.share_id}/>)}
    </Card>
  );
}

export default ShareCard;
