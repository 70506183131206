import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../context/api";
import ShareCard from "./ShareCard";
import { WSContext } from "../../context/websockets";
import { Spinner } from "react-bootstrap";
import { groupBy, timeAgo, timestampToDate } from "../../utils";

function SharesPage() {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const { lastEvent } = useContext(WSContext);
  const [shareItems, setShareItems] = useState([]);
  const [highlightedShares, setHighlightedShares] = useState([]);
  const [sharesProgress, setSharesProgress] = useState({});

  const getAllShares = () => {
    return fetchAPI({
      path: '/shares',
      query: {},
    }).then(response => {
      if (response?.items) {
        setShareItems(response.items);
      }
    });
  };

  useEffect(() => {
    getAllShares();
  }, []);

  useEffect(() => {
    if (!lastEvent) return;
    if (lastEvent?.event === 'share_status') {
      if (lastEvent?.payload?.share_id) {
        setHighlightedShares((prev) => prev.concat(lastEvent.payload.share_id));
      }
      getAllShares();
    }
    if (lastEvent?.event === 'share_progress') {
      const payload = lastEvent.payload;
      setSharesProgress({...sharesProgress, [payload.share_id]: payload});
    }
  }, [lastEvent]);

  const isHighlighted = (item) => {
    return !!highlightedShares.find((uuid) => uuid === item.share_id);
  }


  const groupedShareItems = groupBy(shareItems || [], (item) => {
    if (!item || !item.timestamp) {
      return '';
    }
    const ts = timestampToDate(item.timestamp);
    if (ts.toDateString() === new Date().toDateString()) {
      return 'Today';
    }
    return timeAgo.format(ts);
  });

  return (
    <div>
      <h3>Items shared with annotators</h3>
      <div>
        {!isAPILoading && (
          <span>Total items: {shareItems?.length || 0}</span>
        )}
        {!!isAPILoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className={'ms-1'}
          />
        )}
      </div>
      {Object.keys(groupedShareItems).map((groupName, idx) => (
        <div key={idx} className={'mt-5'}>
          <h4 className={'text-muted mb-2 text-end'}>{groupName}</h4>
          <hr/>
          {groupedShareItems[groupName].map(item => (
            <ShareCard
              key={item.share_id}
              item={item}
              progress={sharesProgress[item.share_id]}
              highlighted={isHighlighted(item)}
              onMutation={() => getAllShares()}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default SharesPage;
