import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { APIContext } from "../context/api";
import { WSContext } from "../context/websockets";

function Menu() {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const { connectionStatus } = useContext(WSContext);
  const [apiMeta, setApiMeta] = useState({});
  const hasAccessToken = !!localStorage.getItem("access_token");

  const loadApiMeta = useCallback(() => {
    return fetchAPI({
      path: '/describe',
      query: {}
    }).then(response => {
      if (response?.success) setApiMeta(response);
    });
  }, []);

  useEffect(() => {
    loadApiMeta();
  }, []);

  return (
    <Navbar bg="light">
      <Container fluid>
        <Navbar.Brand as={Link} to="/"><i>Translation Middleware</i></Navbar.Brand>
        <Navbar.Toggle />
        <Nav className="me-auto">
          <Nav.Link as={Link} exact="true" to="/audio/transcript">Transcripts</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/shares/list">Vendor Shares</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/vendor/upload">Vendor Upload</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/vendor/translated">Vendor Translated</Nav.Link>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <Nav.Link as={Link} exact="true" to="/audio/get">Get Audio</Nav.Link>
          <Nav.Link as={Link} exact="true" to="/translations/get">Get Translation</Nav.Link>
        </Nav>
        <Nav>
          <Navbar.Text className={'me-2'}>
            <Spinner animation="border" size="sm" style={{ opacity: isAPILoading ? 1 : 0 }}/>
          </Navbar.Text>
          <Navbar.Text>
            {!!apiMeta.user_id && (
              <span>{apiMeta.user_id}</span>
            )}
            {!apiMeta.user_id && !hasAccessToken && (
              <Link to={'/login'}>Sign in</Link>
            )}
            <span className={connectionStatus === 'Open' ? 'circle circle-green' : 'circle circle-red'}  />
          </Navbar.Text>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Menu;
