import { Toast, ToastContainer } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { WSContext } from "../context/websockets";
import { Link } from "react-router-dom";
import { timestampToDate } from "../utils";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const { lastEvent } = useContext(WSContext);

  useEffect(() => {
    if (!lastEvent) return;

    if (lastEvent.event === 'share_status') {
      let label = lastEvent.payload.status;
      if (lastEvent.payload.status === 'PUBLISHED') {
        label = 'successfully published'
      }

      setNotifications(prev => prev.concat({
        uuid: lastEvent.uuid,
        title: `Annotators share ${label}`,
        time: timestampToDate(lastEvent.timestamp),
        body: (
          <div>
            <p><code>{lastEvent.payload?.share_id}</code></p>
            <Link to={'/shares/list'}>Show created</Link>
          </div>
        ),
      }));
    }

    if (lastEvent.event === 'translated_batch_status') {
      let label = lastEvent.payload.status;
      if (lastEvent.payload.status === 'PROCESSED') {
        label = 'successfully processed'
      }

      setNotifications(prev => prev.concat({
        uuid: lastEvent.uuid,
        title: `Translated batch ${label}`,
        time: timestampToDate(lastEvent.timestamp),
        body: (
          <div>
            <p><code>{lastEvent.payload?.batch_id}</code></p>
            <Link to={'/vendor/translated'}>Show translated batches</Link>
          </div>
        ),
      }));
    }

  },[lastEvent]);

  const closeItem = (uuid) => {
    setNotifications(prev => prev.filter((item => item.uuid !== uuid)));
  };

  return (
      <ToastContainer position="top-end" className="p-3" style={{zIndex: 10}}>
        {notifications?.map((item, i) => (
          <Toast key={item.uuid} onClose={() => closeItem(item.uuid)}>
            <Toast.Header>
              <strong className="me-auto">{item.title}</strong>
              <small className="text-muted">{item.time?.toLocaleTimeString()}</small>
            </Toast.Header>
            <Toast.Body>{item.body}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
  )
}

export default Notifications;
