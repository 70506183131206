import { Card } from "react-bootstrap";
import PrettyPrintJson from "../helpers/PrettyPrintJson";


function AudioCard({ children, audio }) {
  return (
    <Card body className='mb-2 mt-2'>
      <code>{audio.id}</code>
      <PrettyPrintJson data={audio}/>
      <audio controls>
        <source src={audio.uri} type="audio/ogg" />
      </audio>

      <div>
        {children}
      </div>
    </Card>
  );
}

export default AudioCard;
