import React, { useContext, useState } from "react";

import { Form, Modal, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AlertContext } from "../../context/alert";
import { APIContext } from "../../context/api";
import PrettyPrintJson from "../helpers/PrettyPrintJson";


function CreateShareModel({ show, onClose, enableEscape, currentAudioFilters }) {
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const [audioLengthRequested, setAudioLengthRequested] = useState('');
  const [audioCountRequested, setAudioCountRequested] = useState('');
  const [excludeDuplicates, setExcludeDuplicates] = useState(true);

  const createNewShare = () => {
    const audioLengthRequestedSec = audioLengthRequested ? audioLengthRequested * 60 : audioLengthRequested;

    return fetchAPI({
      path: '/create_annotators_share',
      data: {
        filters: currentAudioFilters,
        params: {
          audio_length_requested_seconds: audioLengthRequestedSec || null,
          audio_count_requested: audioCountRequested || null,
          exclude_duplicates: excludeDuplicates || false,
        },
      },
    }).then(response => {
      if (response?.success) {
        showAlertMessage({
          message: 'Please wait for notification',
          variant: 'success',
          title: `Share ID: ${response.share_id}`,
        });
        onClose(true);
      }
    });
  };

  return (
    <div className="static-modal">
      <Modal show={show} onHide={() => onClose(false)} backdrop={enableEscape ? true : 'static'}
             keyboard={enableEscape}>
        <Modal.Header>
          <Modal.Title>Build zip for annotators</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Filters:</h5>
            <PrettyPrintJson data={currentAudioFilters}/>
          </div>
          <Form.Group className="mb-3 mt-2">
            <Form.Label>
              <div>Requested amount of minutes of audio to include</div>
              <div className={'text-muted'}>Upper limit. Result may have less minutes.</div>
            </Form.Label>
            <Form.Control
              type="number"
              value={audioLengthRequested}
              onChange={(e) => setAudioLengthRequested(e.target.value)}/>
          </Form.Group>

          <Form.Group className="mb-3 mt-2">
            <Form.Label>
              <div>Requested count of audios to include</div>
              <div className={'text-muted'}>Upper limit. Result may have less audios.</div>
            </Form.Label>
            <Form.Control
              type="number"
              value={audioCountRequested}
              onChange={(e) => setAudioCountRequested(e.target.value)}/>
          </Form.Group>

          <Form.Group className="mb-3 mt-2">
            <Form.Check
              type="switch"
              id="ExcludeDuplicates"
              label="Exclude duplicates"
              checked={excludeDuplicates}
              onChange={() => setExcludeDuplicates(!excludeDuplicates)}
            />
            <Form.Label>
              <div className={'text-muted'}>Do not include the audios downloaded previously</div>
            </Form.Label>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isAPILoading} variant={'secondary'} onClick={() => onClose(false)}>Cancel</Button>
          <Button disabled={isAPILoading} variant={'primary'} onClick={() => createNewShare()}>
            Proceed 🚀
            {isAPILoading && (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={'ms-1'}
            />)}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CreateShareModel;
