// getting translations for creating and evaluating models
import React, { useContext, useState } from "react";
import { AlertContext } from "../../context/alert";
import { APIContext } from "../../context/api";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import AudioCard from "../Audio/AudioCard";


function GetAudioPage() {
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const [audioId, setAudioId] = useState('');
  const [audios, setAudios] = useState([]);

  const getAudio = () => {
    return fetchAPI({
      path: '/audio',
      query: {
        // audio_id: 'dF8wMDAwMDAwMDAxNjQ2MTUwNjU0IzI3NTI4OWQ0LTJhYWUtNGZhNC04ZDYzLTIwOThlMThiMWQwZiMwMDAwMDAwMDAwMDAwMjQzMDE0IzAwMDAwMDAwMDAwMDAyNDg4MzQ=',
        audio_id: audioId,
      },
    }).then(response => {
      if (response?.success && !response.item) {
        showAlertMessage({ message: 'Audio with a given ID not found' });
      }
      if (response?.item) {
        setAudios([response.item])
      }
    });
  };

  return (
    <div>
      <h3>Get audio by ID</h3>
      <Form.Group className="mb-3" controlId="audioId">
        <Form.Label>audio_id</Form.Label>
        <Form.Control
          type="text"
          name="audio_id"
          disabled={isAPILoading}
          value={audioId}
          onChange={(e) => setAudioId(e.target.value)}
        />
      </Form.Group>
      <Button onClick={getAudio}>Load</Button>

      {audios?.map((audio) => (
        <AudioCard audio={audio} key={audio.id} />
      ))}
    </div>
  );
}

export default GetAudioPage;
