import React, { useContext } from "react";
import logo from './logo.svg';
import './Home.css';
import { Link } from "react-router-dom";
import { ConfigContext } from "../../context/config";

function Home() {
  const { apiUrl } = useContext(ConfigContext);
  const apiDocsUrl = `${apiUrl}/docs`
  return (
    <div className="App">
      <header className="App-header">
        <div>
          API Docs: <a href={apiDocsUrl}>{apiDocsUrl}</a>
        </div>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to Translation Middleware
        </p>
        <div>
          <p>- <Link to={'/audio/transcript'}>Generate</Link> a zip "share" to provide to annotators.</p>
          <p>- <Link to={'/vendor/upload'}>Upload</Link> translations provided by annotators.</p>
          <p>- Different helpful functions</p>
        </div>
      </header>
    </div>
  );
}

export default Home;
