import { Alert } from "react-bootstrap";
import React, { useEffect, useRef } from "react";

function AlertDismissible({ visible, onClose, variant, message, title }) {
  const alertRef = useRef(null);
  variant = variant || "success";

  useEffect(() => {
    if (visible && alertRef.current) alertRef.current.scrollIntoView();
  }, [visible]);

  if (visible) {
    return (
      <Alert ref={alertRef} variant={variant} onClose={() => onClose()} dismissible>
        {title && (<Alert.Heading>{title}</Alert.Heading>)}
        <div>{message}</div>
      </Alert>
    );
  }
  return null;
}

export default AlertDismissible;
