import React, { createContext, useContext, useState } from "react";
import { ConfigContext } from './config';
import { AlertContext } from "./alert";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export const APIContext = createContext({
  isAPILoading: false,
  fetchAPI: () => {
  },
});


const AuthErrorAlert = ({ response, onClose }) => {
  return (
    <div>
      <div>{response?.detail}</div>
      <Link to="/login" >
        <Button onClick={onClose}>Login</Button>
      </Link>
    </div>
  )
};

export const APIProvider = ({ children }) => {
  const [isAPILoading, setIsAPILoading] = useState(false);
  const { apiUrl } = useContext(ConfigContext);
  const { showAlertMessage, hideAlertMessage } = useContext(AlertContext);

  const fetchAPI = ({
                      path,
                      data,
                      method,
                      filesData = [],
                      query,
                      downloadAsFile = null,
                      noLoading = false
                    }) => {
    path = path.replace(/\/$/, '');
    path = path.replace(/^\//, '');
    const baseUrl = apiUrl.replace(/\/$/, '');
    let url = `${baseUrl}/${path}`;

    if (query) {
      url += '?';
      url += new URLSearchParams(query);
    }

    const isMultipartForm = !!filesData?.length;
    const defaultMethod = !data && !isMultipartForm ? "GET" : "POST";
    const requestMethod = method || defaultMethod;

    const accessToken = localStorage.getItem("access_token");
    const authorizationHeader = accessToken ? `Bearer ${accessToken}` : null;

    let params = {
      method: requestMethod,
      headers: !isMultipartForm ? new Headers({
        Accept: "application/json",
        'Content-Type': "application/json",
        'Authorization': authorizationHeader,
      }) : new Headers({
        'Authorization': authorizationHeader,
      }),
    };
    if (data && !isMultipartForm) {
      params.body = JSON.stringify(data);
    }

    if (filesData?.length > 0) {
      const formData = new FormData();
      filesData.forEach((file, i) => {
        formData.append(file[0], file[1]);
      });

      Object.keys(data).forEach((dataKey) => {
        formData.append(dataKey, data[dataKey]);
      });

      params.body = formData;
    }

    if (!noLoading) {
      setIsAPILoading(true);
    }
    return fetch(
      url,
      params,
    )
      .then(res => !downloadAsFile ? res : res.blob())
      .then(res => {
        if (downloadAsFile) {
          var url = window.URL.createObjectURL(res);
          var a = document.createElement('a');
          a.href = url;
          a.download = downloadAsFile;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again
        } else {
          return res;
        }
      })
      .then(async (res) => {
        if (downloadAsFile) {
          return res;
        }
        const response = await res.json();
        if (res.status === 401) {
          showAlertMessage({
            message: <AuthErrorAlert response={response} onClose={() => hideAlertMessage()} />,
            variant: 'danger',
            title: 'Auth error',
          });
        } else if (response?.success === false) {
          showAlertMessage({ message: response.error, variant: 'warning', title: 'Request error' });
        } else {
          hideAlertMessage();
        }
        return response;
      })
      .catch(error => {
        console.error(error);
        showAlertMessage({ message: error?.message, variant: 'danger', title: 'API Error' });
      })
      .finally(() => setIsAPILoading(false));
  }

  return (
    <APIContext.Provider value={{ fetchAPI, isAPILoading }}>
      {children}
    </APIContext.Provider>
  );
}
