import { Badge, Card } from "react-bootstrap";
import PrettyPrintJson from "../helpers/PrettyPrintJson";
import { timestampToDate } from "../../utils";
import React from "react";


function TranslatedBatchCard({ children, item, highlighted }) {
  if (!item) {
    return null;
  }

  let createdAt = '';
  if (item.timestamp) {
    createdAt = timestampToDate(item.timestamp).toLocaleString();
  }

  return (
    <Card body className='mb-2 mt-2' style={highlighted ? {borderColor: 'gold'} : {}}>
      <strong><code>{item.original_filename}</code></strong>
      <p>Created at: {createdAt}</p>

      <div className={'mb-2'}>
        {!!item?.is_dry_run && (
          <Badge bg="primary" className='me-2'>
            Dummy
          </Badge>
        )}
        {!!item?.share_id && (
          <Badge bg="primary" className='me-2'>
            Linked to share
          </Badge>
        )}
      </div>

      <PrettyPrintJson data={item}/>
      <div>
        {children}
      </div>
    </Card>
  );
}

export default TranslatedBatchCard;
