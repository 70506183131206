import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/home/Home";
import NoPage from "./components/NoPage";
import VendorUploadPage from "./components/VendorUpload/VendorUploadPage";
import GetTranslationsPage from "./components/Translations/GetTranslationsPage";
import TranscriptAudioPage from "./components/Audio/TranscriptAudioPage";
import GetAudioPage from "./components/Audio/GetAudioPage";
import SharesPage from "./components/Shares/SharesPage";
import TranslatedBatchesPage from "./components/VendorUpload/TranslatedBatchesPage";
import LoginPage from "./components/Auth/LoginPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout fluid/>}>
        <Route index element={<Home/>}/>
        <Route path="*" element={<NoPage/>}/>
        <Route path="login" element={<LoginPage/>}/>
      </Route>
      <Route path="/audio" element={<Layout/>}>
        <Route path="transcript" element={<TranscriptAudioPage/>}/>
        <Route path="get" element={<GetAudioPage/>}/>
      </Route>
      <Route path="/vendor" element={<Layout/>}>
        <Route path="upload" element={<VendorUploadPage/>}/>
        <Route path="translated" element={<TranslatedBatchesPage/>}/>
      </Route>
      <Route path="/translations" element={<Layout/>}>
        <Route path="get" element={<GetTranslationsPage/>}/>
      </Route>
      <Route path="/shares" element={<Layout/>}>
        <Route path="list" element={<SharesPage/>}/>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
