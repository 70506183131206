// getting translations for creating and evaluating models

import React, { useContext, useState } from "react";
import { AlertContext } from "../../context/alert";
import { APIContext } from "../../context/api";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import PrettyPrintJson from "../helpers/PrettyPrintJson";


function GetTranslationsPage() {
  const { showAlertMessage } = useContext(AlertContext);
  const { fetchAPI, isAPILoading } = useContext(APIContext);

  const [audioId, setAudioId] = useState('');
  const [translationItems, setTranslationItems] = useState([]);

  const getAudio = () => {
    return fetchAPI({
      path: '/translations',
      query: {
        audio_id: audioId,
      },
    }).then(response => {
      if (response?.success && !response.items?.length) {
        showAlertMessage({ message: 'Translation for Audio with a given ID not found' });
      }
      if (response?.items) {
        setTranslationItems(response.items);
      }
    });
  };

  return (
    <div>
      <h3>Get translations by ID</h3>
      <Form.Group className="mb-3" controlId="audioId">
        <Form.Label>audio_id</Form.Label>
        <Form.Control
          type="text"
          name="audio_id"
          disabled={isAPILoading}
          value={audioId}
          onChange={(e) => setAudioId(e.target.value)}
        />
      </Form.Group>
      <Button onClick={getAudio}>Load</Button>

      {translationItems?.map((item) => (
        <PrettyPrintJson data={item} />
      ))}
    </div>
  );
}

export default GetTranslationsPage;
