import React, { useState } from "react";
import parse from 'html-react-parser';
import { confirmable, createConfirmation } from 'react-confirm';
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const Dialog = ({
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  title = 'Confirm',
  confirmation,
  show = true,
  proceed,
  enableEscape = true,
  input = false,
  inputDefaultValue = '',
}) => {
  const [inputValue, setInputValue] = useState(inputDefaultValue);
  return (
    <div className="static-modal">
      <Modal show={show} onHide={() => proceed(false)} backdrop={enableEscape ? true : 'static'}
             keyboard={enableEscape}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!input && confirmation}
          {input && (
            <Form.Group className="mb-3">
              <Form.Label>{parse(confirmation)}</Form.Label>
              <Form.Control
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}/>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => proceed(false)}>{cancelLabel}</Button>
          <Button variant={'primary'} onClick={() => proceed(input ? inputValue : true)}>{okLabel}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const confirmableComponent = confirmable(Dialog);

// create confirm function
export default createConfirmation(confirmableComponent);
