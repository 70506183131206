import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../context/api";
import { WSContext } from "../../context/websockets";
import TranslatedBatchCard from "./TranslatedBatchCard";
import Button from "react-bootstrap/Button";
import confirm from "../confirm";

function TranslatedBatchesPage() {
  const { fetchAPI, isAPILoading } = useContext(APIContext);
  const { lastEvent } = useContext(WSContext);
  const [translatedBatches, setTranslatedBatches] = useState([]);
  const [highlightedItems, setHighlightedItems] = useState([]);

  const getAllTranslatedBatches = () => {
    return fetchAPI({
      path: '/translated_batches',
      query: {
      },
    }).then(response => {
      if (response?.items) {
        setTranslatedBatches(response.items);
      }
    });
  };

  const deleteBatch = async (batchId) => {
    const result = await confirm({ confirmation: `Are you sure you want to delete ${batchId}?` });
    if (!result) return;

    return fetchAPI({
      method: 'DELETE',
      path: '/translated_batches',
      query: {
        batch_id: batchId,
      },
    }).then(response => {
      if (!response?.success) {
        return;
      }
      getAllTranslatedBatches();
    });
  }

  useEffect(() => {
    if (!lastEvent) return;
    if (lastEvent.event === 'translated_batch_status') {
      if (lastEvent?.payload?.batch_id) {
        setHighlightedItems((prev) => prev.concat(lastEvent.payload.batch_id));
      }
      getAllTranslatedBatches();
    }
  },[lastEvent]);

  useEffect(() => {
    getAllTranslatedBatches();
  }, []);

  const isHighlighted = (item) => {
    return !!highlightedItems.find((uuid) => uuid === item.batch_id);
  }

  return (
    <div>
      <h3>Translated Batches</h3>
      <div>
        Total items: {translatedBatches?.length || 0}
      </div>


      {translatedBatches?.map((item) => (
        <TranslatedBatchCard key={item.batch_id} item={item} highlighted={isHighlighted(item)}>
          <Button variant={'danger'} onClick={() => deleteBatch(item.batch_id)}>Delete</Button>
        </TranslatedBatchCard>
      ))}
    </div>
  );
}

export default TranslatedBatchesPage;
